import React from 'react';

import { randInt } from '../utils/misc';

const Tile = ({dim, colorRange}: {dim: number, colorRange: {colMin: number, colMax: number}}) => {

  const generateHslColor = () => [
    randInt(colorRange.colMin, colorRange.colMax),
    randInt(10, 30) + '%',
    randInt(20, 40) + '%',
  ];

  return <div
    className='tile'
    style={{
      backgroundColor: `hsl(${generateHslColor().join(' ')})`,
      height: dim + 'px',
      width: dim + 'px',
    }}
  />

}

export default Tile;