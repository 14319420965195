import React from 'react';

import Tile from './Tile';

import { randInt } from '../utils/misc';

const Tiles = () => {

  const tiles = (count: number, dim: number) => {
    const tiles = [];
    const colMin = Math.max(randInt(0, 360), 0);
    const colMax = Math.min(colMin + 30, 360);
    for (let i = 0; i < count; i++) {
      tiles.push(
        <Tile dim={dim} colorRange={{colMin, colMax}}/>
      )
    }
    return tiles;
  };

  const genRows = () => {
    const items = [];
    let totalHeight = 0;
    for (let i = 0; i < 10; i++) {
      const dim = randInt(60, 200);
      totalHeight += dim;

      if (totalHeight > window.innerHeight) break;

      const rowLen = (window.innerWidth / dim) + 1;
      items.push(
        <div style={{height: `${dim+4}px`}} className='tileRow'>
          {tiles(rowLen, dim-4)}
        </div>
      )
    }
    return items;
  }

  return <div className='tileContainer'>
    {genRows()}
  </div>;
}

export default Tiles;