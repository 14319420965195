import React from 'react';
import Tiles from './components/Tiles';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Tiles />
      </header>
    </div>
  );
}

export default App;
